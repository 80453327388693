import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import useForm, { DEFAULT_FIELD } from '../../hooks/useForm';
import Email, { validator as emailValidator } from '../Form/inputs/Email';
import Password, { validator as passwordValidator } from '../Form/inputs/Password';
import Button from '../Button';
import ButtonWithLoading from '../Form/inputs/ButtonWithLoading';

import FacebookIcon from '../CustomIcons/Facebook';
import GoogleIcon from '../CustomIcons/Google';

const translations = {
  ui: {
    buttons: {
      emailSignIn: {
        label: 'Sign In'
      },
      googleSignIn: 'Sign In with Google',
      facebookSignIn: 'Sign In with Facebook',
    },
  },
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
}));

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const validators = {
  email: emailValidator,
  password: passwordValidator,
}

const submitEmailSignIn = ({ event, inputs, props, setInputs }) => {
  const { email, password } = inputs.fields;
  event.preventDefault();

  return props.firebase
    .doSignInWithEmailAndPassword(email.value, password.value)
    .then(() => {
      setInputs(nextInputs => ({
        ...nextInputs,
        fields: {
          ...nextInputs.fields,
          email: DEFAULT_FIELD,
          password: DEFAULT_FIELD,
        },
      }));
      navigate(ROUTES.HOME);
      return ({
        result: {},
      });
    })
    .catch(error => {
      return ({
        errors: error,
        result: {},
      });
    });

};

const SignInFormBase = props => {
  const classes = useStyles();
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success 
  } = useForm(props, submitEmailSignIn, { email: DEFAULT_FIELD, password: DEFAULT_FIELD }, validators);

  return (
    <form onSubmit={event => handleSubmit(event, inputs)} className={classes.container}>
      <Email 
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs}
        className={classes.fields}
      />
      <Password
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs} 
        className={classes.fields}
      />
      <Box className={classes.formButtons}>
        <ButtonWithLoading type="submit" loading={loading} success={success} label={translations.ui.buttons.emailSignIn.label} />
      </Box>

      {inputs.errors && <p>{inputs.errors.message}</p>}
    </form>
  );
}

const SignInGoogleBase = props => {
  const [ state, setState ] = useState({ error: null });
  const onSubmit = event => {
    props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setState({ error });
      });

    event.preventDefault();
  };
  const { error } = state;

  return (
    <form onSubmit={onSubmit}>
      <Button type="submit" startIcon={<GoogleIcon />}>{translations.ui.buttons.googleSignIn}</Button>
      {error && <p>{error.message}</p>}
    </form>
  );

}

const SignInFacebookBase = props => {
  const [state, setState] = useState({ error: null });
  const onSubmit = event => {
    props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setState({ error });
      });

    event.preventDefault();
  };
  const { error } = state;

  return (
    <form onSubmit={onSubmit}>
      <Button type="submit" startIcon={<FacebookIcon/>} disabled>{translations.ui.buttons.facebookSignIn}</Button>

      {error && <p>{error.message}</p>}
    </form>
  );

}


const SignInForm = withFirebase(SignInFormBase);

const SignInGoogle = withFirebase(SignInGoogleBase);

const SignInFacebook = withFirebase(SignInFacebookBase);

export default SignInForm;

export { SignInGoogle, SignInFacebook };
